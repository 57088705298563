<template>
  <b-card class="text-center">
    <h3 class="mb-4">{{ $t('forgot_password') }}</h3>
      <b-row>
        <b-col sm="4" offset="4">
          <form @submit.prevent="reset" @keydown="form.onKeydown($event)" class="reset-form">
            <div class="form-group">
              <label for="exampleInputEmail1" class="text-uppercase">{{ $t('email') }}</label>
              <input type="text" name="email" class="form-control" v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }">
              <has-error :form="form" field="email" />
            </div>

            <div class="form-group">
              <button :disabled="form.busy" type="submit" class="btn btn-danger">{{ $t('reset') }}</button>
            </div>
            <router-link :to="{ name: 'login' }" class="small">{{ $t('login') }}</router-link>
            <br>
            <router-link :to="{ name: 'register' }" class="small">{{ $t('register') }}</router-link>
          </form>
        </b-col>
      </b-row>
  </b-card>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Form from 'vform'
export default {
  name: 'reset',
  layout: 'messages',
  metaInfo () {
    return { title: this.$t('forgot_password') }
  },
  data: () => ({
    form: new Form({
      email: ''
    })
  }),
  methods: {
    async reset () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const { data } = await this.form.post(apiUrl + 'password/reset-request').then(response => {
        if (response.data.status === 'success') {
          Swal.fire({
            icon: 'info',
            title: 'Інфо!',
            text: response.data.msg,
            reverseButtons: true,
            confirmButtonText: this.$t('ok'),
            cancelButtonText: this.$t('cancel')
          })
        }
        // this.$router.push({ name: 'dashboard' })
      })
      this.$root.$refs.loading.finish()
    }
  }
}
</script>
